<template>

  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr2">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="donghang-gamefram" scrolling="no" frameborder="0" allowtransparency="true"
                    src="https://named.com/minigame/nball/powerball5/pc"></iframe>
          </leisure-game-ifram-view>
        </div>

      </div>
      <div class="m2">

        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange">네임드 파워볼 5분 </span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t text-skyblue">파워볼 홀/짝 , 오버/언더</span>
              <div class="mg_btn b4 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN,leisureGameInfo.leisureGame.id,1, poddevenConfig.odds1, poddevenConfig,'파워볼 홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ poddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b4 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN,leisureGameInfo.leisureGame.id,2,  poddevenConfig.odds2, poddevenConfig,'파워볼 짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ poddevenConfig.odds2 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER,leisureGameInfo.leisureGame.id,1, poverunderConfig.odds1, poverunderConfig,'파워볼 오버')">
                <div class="r rred">
                  <span class="n">오버</span>
                  <span class="b">{{ poverunderConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER,leisureGameInfo.leisureGame.id,2,  poverunderConfig.odds2, poverunderConfig,'파워볼 언더')">
                <div class="r rblue">
                  <span class="n">언더</span>
                  <span class="b">{{ poverunderConfig.odds2 }}</span>
                </div>
              </div>
            </div>

            <!--파워볼 조합-->
            <div class="mg">
              <span class="t text-skyblue">파워볼 조합</span>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,1, powerballCrossConfig.odds1, powerballCrossConfig,'홀오버')">
                <div class="r rblue">
                  <span class="n">홀오버</span>
                  <span class="b">{{ powerballCrossConfig.odds1 }}</span>
                </div>
              </div>

              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,2, powerballCrossConfig.odds2, powerballCrossConfig,'홀언더')">
                <div class="r rred">
                  <span class="n">홀언더</span>
                  <span class="b">{{ powerballCrossConfig.odds2 }}</span>
                </div>
              </div>

              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 3}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,3, powerballCrossConfig.odds3, powerballCrossConfig,'짝오버')">
                <div class="r rblue">
                  <span class="n">짝오버</span>
                  <span class="b">{{ powerballCrossConfig.odds3 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 4}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,4, powerballCrossConfig.odds4, powerballCrossConfig,'짝언더')">
                <div class="r rred">
                  <span class="n">짝언더</span>
                  <span class="b">{{ powerballCrossConfig.odds4 }}</span>
                </div>
              </div>

            </div>

            <!--일반볼-->
            <div class="mg">
              <span class="t text-white">일반볼 홀/짝 , 오버/언더</span>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN,leisureGameInfo.leisureGame.id,1, noddevenConfig.odds1, noddevenConfig,'일반볼 홀')">
                <div class="r rblue">
                  <span class="n">홀</span>
                  <span class="b">{{ noddevenConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN,leisureGameInfo.leisureGame.id,2,  noddevenConfig.odds2, noddevenConfig,'일반볼 짝')">
                <div class="r rred">
                  <span class="n">짝</span>
                  <span class="b">{{ noddevenConfig.odds2 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER,leisureGameInfo.leisureGame.id,1, noverunderConfig.odds1, noverunderConfig,'일반볼 오버')">
                <div class="r rred">
                  <span class="n">오버</span>
                  <span class="b">{{ noverunderConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER,leisureGameInfo.leisureGame.id,2,  noverunderConfig.odds2, noverunderConfig,'일반볼 언더')">
                <div class="r rblue">
                  <span class="n">언더</span>
                  <span class="b">{{ noverunderConfig.odds2 }}</span>
                </div>
              </div>
            </div>

            <div class="mg">
              <span class="t text-white">일반볼 대,중,소</span>
              <div class="mg_btn b3 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,1, nbmsConfig.odds1, nbmsConfig,'일반볼 대')">
                <div class="r rblue">
                  <span class="n">대</span>
                  <span class="b">{{ nbmsConfig.odds1 }}</span>
                </div>
              </div>
              <div class="mg_btn b3 bg-green"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,2, nbmsConfig.odds2, nbmsConfig,'일반볼 중')">
                <div class="r rred">
                  <span class="n">중</span>
                  <span class="b">{{ nbmsConfig.odds2 }}</span>
                </div>
              </div>
              <div class="mg_btn b3 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS && selectedInfo.selectedWay === 3}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS,leisureGameInfo.leisureGame.id,3, nbmsConfig.odds3, nbmsConfig,'일반볼 소')">
                <div class="r rblue">
                  <span class="n">소</span>
                  <span class="b">{{ nbmsConfig.odds3 }}</span>
                </div>
              </div>
            </div>

            <!--일반볼 조합-->
            <div class="mg">
              <span class="t text-skyblue">일반볼 조합</span>
              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,1, normalballCrossConfig.odds1, normalballCrossConfig,'홀오버')">
                <div class="r rblue">
                  <span class="n">홀오버</span>
                  <span class="b">{{ normalballCrossConfig.odds1 }}</span>
                </div>
              </div>

              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,2, normalballCrossConfig.odds2, normalballCrossConfig,'홀언더')">
                <div class="r rred">
                  <span class="n">홀언더</span>
                  <span class="b">{{ normalballCrossConfig.odds2 }}</span>
                </div>
              </div>

              <div class="mg_btn b4  bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 3}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,3, normalballCrossConfig.odds3, normalballCrossConfig,'짝오버')">
                <div class="r rblue">
                  <span class="n">짝오버</span>
                  <span class="b">{{ normalballCrossConfig.odds3 }}</span>
                </div>
              </div>
              <div class="mg_btn b4  bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS && selectedInfo.selectedWay === 4}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS,leisureGameInfo.leisureGame.id,4, normalballCrossConfig.odds4, normalballCrossConfig,'짝언더')">
                <div class="r rred">
                  <span class="n">짝언더</span>
                  <span class="b">{{ normalballCrossConfig.odds4 }}</span>
                </div>
              </div>

            </div>

          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            파워볼 5분
          </leisure-bet-cart-comp>
        </div>
      </div>

      <div class="m3">


        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>


    </div>
  </div> <!--./mini_game_bet end--->

</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";
export default {

  name: "NamedNewPowerBall5M",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_NAMED_NEWPOWERBALL_5S,
    }
  },
  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data


          //배당설정
          //파워볼
          this.poddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN)
          })
          this.poverunderConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_OVERUNDER)
          })

          //일반볼
          this.noddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_ODDEVEN)
          })
          this.noverunderConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALLTOTAL_OVERUNDER)
          })
          this.nbmsConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_NORMALBALL_BMS)
          })
          //홀짝+오버언더 조합
          this.powerballCrossConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_ODDEVEN_OVERUNDER_CROSS)
          })
          this.normalballCrossConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMED_NEWPOWERBALL_5S_POWERBALL_NOMALBALL_ODDEVEN_OVERUNDER_CROSS)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP, leisureConst.LEISURE_COMP_NAMED);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID, this.kind);
  }
}
</script>

<style scoped>
@media screen and (max-width: 540px) {
  .dmr2 {
    min-height: 400px !important;
  }
}

@media screen and (max-width: 414px) {
  .dmr2 {
    min-height: 360px !important;
  }
}

@media screen and (max-width: 375px) {
  .dmr2 {
    min-height: 350px !important;
  }
}

@media screen and (max-width: 360px) {
  .dmr2 {
    min-height: 350px;
  }
}
</style>